
import {get,post} from "@/axios";

//网站页面
export function getSiteList(data) {
    return post('/api/sites/list',data);
}
export function addSite(data) {
    return post('/api/sites/add',data);
}
export function editSite(data) {
    console.log(data,'接收到的数据')
    return post('/api/sites/edit',data);
}

export function batchAdd(data) {
    return post('/api/sites/batchAdd',data);
}
export function batchEdit(data) {
    return post('/api/sites/batchEdit',data);
}