
import {get,post} from "@/axios";

//分组页面
export function getGroupList(data) {
    return post('/api/groups/list',data);
}
export function addGroup(data) {
    console.log(data,'接收到的数据')
    return post('/api/groups/add',data);
    
}
export function editGroup(data) {
    console.log(data,'接收到的数据')
    return post('/api/groups/edit',data);
}

export function getAllGroupList(data) {
    return get('/api/groups/getAllGroups',data);
}

